import { Image } from "react-grid-gallery";
import family from "../assets/family.jpg"
import family2 from "../assets/family2.jpg";
import family3 from "../assets/family3.jpg";
import family4 from "../assets/family4.jpg";
import family5 from "../assets/family5.jpg";
import family7 from "../assets/family7.jpg";
import family8 from "../assets/family8.jpg";
import family9 from "../assets/family9.jpg";
import family10 from "../assets/family10.jpg";
import family11 from "../assets/family11.jpg";
import family12 from "../assets/family12.jpg";
import family13 from "../assets/family13.jpg";
import family14 from "../assets/family14.jpg";
import oldlogo from "../assets/oldlogo.jpg";
import fund from "../assets/fund.png";
import soccer from "../assets/soccer.jpg";
import soccer2 from "../assets/soccer2.jpg";
import soccer3 from "../assets/soccer3.jpg";
import soccer4 from "../assets/soccer4.jpg";
import soccer5 from "../assets/soccer5.jpg";
import soccer6 from "../assets/soccer6.jpg";
import soccer7 from "../assets/soccer7.jpg";
import soccer8 from "../assets/soccer8.jpg";
import soccer9 from "../assets/soccer9.jpg";
import soccer10 from "../assets/soccert.jpg";
import stories from "../assets/stories.jpg";
import stories2 from "../assets/stories2.jpg";
import stories3 from "../assets/stories3.jpg";
import stories4 from "../assets/stories4.jpg";
import stories5 from "../assets/stories5.jpg";
import stories6 from "../assets/stories6.jpg";
import tshirt from "../assets/tshirt.png";
import FiveK from "../assets/5k.png";
import house from "../assets/house.jpg";
import house1 from "../assets/house1.jpg";
import house2 from "../assets/house2.jpg";
import house3 from "../assets/house3.jpg";
import house4 from "../assets/house4.jpg";
import house5 from "../assets/house5.jpg";
import house6 from "../assets/house6.jpg";
import house7 from "../assets/house7.jpg";
import house8 from "../assets/house8.jpg";
import house9 from "../assets/house9.jpg";
import house10 from "../assets/house10.jpg";
import house11 from "../assets/house11.jpg";
import house12 from "../assets/house12.jpg";
import house13 from "../assets/house13.jpg";
import house14 from "../assets/house14.jpg";
import house15 from "../assets/house15.jpg";
import house16 from "../assets/house16.jpg";
import house17 from "../assets/house17.jpg";
import house18 from "../assets/house18.jpg";
import house19 from "../assets/house19.jpg";
import house20 from "../assets/house20.jpg";

export interface CustomImage extends Image {
  original: string;
}

export const images: CustomImage[] = [
  {
    src: house,
    original: house,
    width: 250,
    height: 174,
    tags: [
      { value: "Clinic", title: "Clinic" },
    ],
    caption: "Elleny Clinic",
  },
  {
    src: house1,
    original: house1,
    width: 250,
    height: 174,
    tags: [
      { value: "Clinic", title: "Clinic" },
    ],
    caption: "Elleny Clinic",
  },
  {
    src: house2,
    original: house2,
    width: 250,
    height: 174,
    tags: [
      { value: "Clinic", title: "Clinic" },
    ],
    caption: "Elleny Clinic",
  },
  {
    src: house3,
    original: house3,
    width: 250,
    height: 174,
    tags: [
      { value: "Clinic", title: "Clinic" },
    ],
    caption: "Elleny Clinic",
  },
  {
    src: house4,
    original: house4,
    width: 250,
    height: 174,
    tags: [
      { value: "Clinic", title: "Clinic" },
    ],
    caption: "Elleny Clinic",
  },
  {
    src: house5,
    original: house5,
    width: 250,
    height: 174,
    tags: [
      { value: "Clinic", title: "Clinic" },
    ],
    caption: "Elleny Clinic",
  },
  {
    src: house6,
    original: house6,
    width: 250,
    height: 174,
    tags: [
      { value: "Clinic", title: "Clinic" },
    ],
    caption: "Elleny Clinic",
  },
  {
    src: house7,
    original: house7,
    width: 250,
    height: 174,
    tags: [
      { value: "Clinic", title: "Clinic" },
    ],
    caption: "Elleny Clinic",
  },
  {
    src: house8,
    original: house8,
    width: 250,
    height: 174,
    tags: [
      { value: "Clinic", title: "Clinic" },
    ],
    caption: "Elleny Clinic",
  },
  {
    src: house9,
    original: house9,
    width: 250,
    height: 174,
    tags: [
      { value: "Clinic", title: "Clinic" },
    ],
    caption: "Elleny Clinic",
  },
  {
    src: house10,
    original: house10,
    width: 250,
    height: 174,
    tags: [
      { value: "Clinic", title: "Clinic" },
    ],
    caption: "Elleny Clinic",
  },
  {
    src: house11,
    original: house11,
    width: 250,
    height: 174,
    tags: [
      { value: "Clinic", title: "Clinic" },
    ],
    caption: "Elleny Clinic",
  },
  {
    src: house12,
    original: house12,
    width: 250,
    height: 174,
    tags: [
      { value: "Clinic", title: "Clinic" },
    ],
    caption: "Elleny Clinic",
  },
  {
    src: house13,
    original: house13,
    width: 250,
    height: 174,
    tags: [
      { value: "Clinic", title: "Clinic" },
    ],
    caption: "Elleny Clinic",
  },
  {
    src: house14,
    original: house14,
    width: 250,
    height: 174,
    tags: [
      { value: "Clinic", title: "Clinic" },
    ],
    caption: "Elleny Clinic",
  },
  {
    src: house15,
    original: house15,
    width: 250,
    height: 174,
    tags: [
      { value: "Clinic", title: "Clinic" },
    ],
    caption: "Elleny Clinic",
  },
  {
    src: house16,
    original: house16,
    width: 250,
    height: 174,
    tags: [
      { value: "Clinic", title: "Clinic" },
    ],
    caption: "Elleny Clinic",
  },
  {
    src: house,
    original: house,
    width: 250,
    height: 174,
    tags: [
      { value: "Clinic", title: "Clinic" },
    ],
    caption: "Elleny Clinic",
  },
  {
    src: house17,
    original: house17,
    width: 250,
    height: 174,
    tags: [
      { value: "Clinic", title: "Clinic" },
    ],
    caption: "Elleny Clinic",
  },
  {
    src: house18,
    original: house18,
    width: 250,
    height: 174,
    tags: [
      { value: "Clinic", title: "Clinic" },
    ],
    caption: "Elleny Clinic",
  },
  {
    src: house19,
    original: house19,
    width: 250,
    height: 174,
    tags: [
      { value: "Clinic", title: "Clinic" },
    ],
    caption: "Elleny Clinic",
  },
  {
    src: house20,
    original: house20,
    width: 250,
    height: 174,
    tags: [
      { value: "Clinic", title: "Clinic" },
    ],
    caption: "Elleny Clinic",
  },
  {
    src: family,
    original: family,
    width: 250,
    height: 174,
    tags: [
      { value: "Family", title: "Family" },
    ],
    caption: "Elleny and her family",
  },
  {
    src: family2,
    original: family2,
    width: 310,
    height: 212,
    tags: [
      { value: "Family", title: "Family" },
    ],
    caption: "Elleny and her family",
  },
  {
    src: family3,
    original: family3,
    width: 320,
    height: 212,
    tags: [
      { value: "Family", title: "Family" },
    ],
    caption: "Elleny and her family",
  },
  {
    src: family4,
    original: family4,
    width: 280,
    height: 212,
    caption: "Elleny and her family",
  },
  {
    src: family5,
    original: family5,
    width: 320,
    height: 212,
    caption: "Elleny and her family",
  },
  {
    src: family7,
    original: family7,
    width: 320,
    height: 212,
    tags: [
      { value: "Family", title: "Family" },
    ],
    caption: "Elleny and her family",
  },
  {
    src: family8,
    original: family8,
    width: 320,
    height: 212,
    caption: "Elleny and her family",
  },
  {
    src: family9,
    original: family9,
    width: 300,
    height: 212,
    caption: "Elleny and her family",
  },
  {
    src: family10,
    original: family10,
    width: 300,
    height: 212,
    caption: "Elleny and her family",
  },
  {
    src: family11,
    original: family11,
    width: 320,
    height: 212,
    tags: [
      { value: "Family", title: "Family" },
    ],
    caption: "Elleny and her family",
  },
  {
    src: family12,
    original: family12,
    width: 320,
    height: 212,
    tags: [
      { value: "Family", title: "Family" },
    ],
    caption: "Elleny and her family",
  },
  {
    src: family13,
    original: family13,
    width: 300,
    height: 212,
    tags: [
      { value: "Family", title: "Family" },
    ],
    caption: "Elleny and her family",
  },
  {
    src: family14,
    original: family14,
    width: 200,
    height: 212,
    caption: "Elleny",
  },
  {
    src: fund,
    original: fund,
    width: 320,
    height: 212,
    tags: [
      { value: "Fundraising", title: "Fundraising" },
      { value: "Dinner", title: "Dinner"}
    ],
    caption: "Fundraising Dinner",
  },
  {
    src: oldlogo,
    original: oldlogo,
    width: 270,
    height: 212,
    tags: [
      { value: "OldLogo", title: "Old logo" },
    ],
    caption: "Old Logo",
  },
  {
    src: soccer,
    original: soccer,
    width: 320,
    height: 212,
    tags: [
      { value: "Soccer", title: "Soccer" },
      { value: "Tournament", title: "Tournament"}
    ],
    caption: "Soccer Tournament",
  },
  {
    src: soccer2,
    original: soccer2,
    width: 280,
    height: 212,
    tags: [
      { value: "Soccer", title: "Soccer" },
      { value: "Tournament", title: "Tournament"}
    ],
    caption: "Soccer Tournament",
  },
  {
    src: soccer3,
    original: soccer3,
    width: 300,
    height: 212,
    tags: [
      { value: "Soccer", title: "Soccer" },
      { value: "Tournament", title: "Tournament"}
    ],
    caption: "Soccer Tournament",
  },
  {
    src: soccer4,
    original: soccer4,
    width: 270,
    height: 212,
    tags: [
      { value: "Soccer", title: "Soccer" },
      { value: "Tournament", title: "Tournament"}
    ],
    caption: "Soccer Tournament",
  },
  {
    src: soccer5,
    original: soccer5,
    width: 300,
    height: 212,
    tags: [
      { value: "Soccer", title: "Soccer" },
      { value: "Tournament", title: "Tournament"}
    ],
    caption: "Soccer Tournament",
  },
  {
    src: soccer6,
    original: soccer6,
    width: 300,
    height: 212,
    tags: [
      { value: "Soccer", title: "Soccer" },
      { value: "Tournament", title: "Tournament"}
    ],
    caption: "Soccer Tournament",
  },
  {
    src: soccer7,
    original: soccer7,
    width: 280,
    height: 212,
    tags: [
      { value: "Soccer", title: "Soccer" },
      { value: "Tournament", title: "Tournament"}
    ],
    caption: "Soccer Tournament",
  },
  {
    src: soccer8,
    original: soccer8,
    width: 280,
    height: 212,
    tags: [
      { value: "Soccer", title: "Soccer" },
      { value: "Tournament", title: "Tournament"}
    ],
    caption: "Soccer Tournament",
  },
  {
    src: soccer9,
    original: soccer9,
    width: 280,
    height: 212,
    tags: [
      { value: "Soccer", title: "Soccer" },
      { value: "Tournament", title: "Tournament"}
    ],
    caption: "Soccer Tournament",
  },
  {
    src: soccer10,
    original: soccer10,
    width: 280,
    height: 212,
    tags: [
      { value: "Soccer", title: "Soccer" },
      { value: "Tournament", title: "Tournament"}
    ],
    caption: "Soccer Tournament",
  },
  {
    src: FiveK,
    original: FiveK,
    width: 280,
    height: 212,
    tags: [
      { value: "Run", title: "Run" },
      { value: "Fundraising", title: "Fundrasing" },
    ],  
    caption: "Fundraising 5K Run",
    
  },
  {
    src: stories,
    original: stories,
    width: 250,
    height: 212,
    tags: [
      { value: "Run", title: "Run" },
      { value: "Fundraising", title: "Fundrasing" },
    ],
    caption: "Fundraising 5K Run",
  },
  {
    src: stories2,
    original: stories2,
    width: 250,
    height: 212,
    tags: [
      { value: "Run", title: "Run" },
      { value: "Fundraising", title: "Fundrasing" },
    ],
    caption: "Fundraising 5K Run",
  },
  {
    src: stories3,
    original: stories3,
    width: 280,
    height: 212,
    tags: [
      { value: "Run", title: "Run" },
      { value: "Fundraising", title: "Fundrasing" },
    ],
    caption: "Fundraising 5K Run",
  },
  {
    src: stories4,
    original: stories4,
    width: 280,
    height: 212,
    tags: [
      { value: "Run", title: "Run" },
      { value: "Fundraising", title: "Fundrasing" },
    ],
    caption: "Fundraising 5K Run",
  },
  {
    src: stories5,
    original: stories5,
    width: 250,
    height: 212,
    tags: [
      { value: "Run", title: "Run" },
      { value: "Fundraising", title: "Fundrasing" },
    ],
    caption: "Fundraising 5K Run",
  },
  {
    src: stories6,
    original: stories6,
    width: 250,
    height: 212,
    tags: [
      { value: "Run", title: "Run" },
      { value: "Fundraising", title: "Fundrasing" },
    ],
    caption: "Fundraising 5K Run",
  },
  {
    src: tshirt,
    original: tshirt,
    width: 200,
    height: 212,
    tags: [
      { value: "Tshirt", title: "T-Shirt" },
    ],
    caption: "T-Shirt",
  },


];